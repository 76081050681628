<template>
  <div class="question__wrapper">
    <div class="question__header">
      <h3 class="question__title">
        {{ this.questionData.question }}
      </h3>
      <p v-if="questionData.prompt" class="question__prompt">
        {{ this.questionData.prompt }}
      </p>
    </div>
    <div
      class="question__inner"
      :class="{
        four: this.questionData.answers.length === 4,
        two: this.questionData.answers.length === 2,
        fourImages: this.questionData.fourImages === true,
        col: this.questionData.displayInCol,
      }"
    >
      <Answer
        v-for="(answerData, index) of this.questionData.answers"
        :key="questionData[index]"
        :answerData="answerData"
        @add-point="$emit('add-point', answerData.isRight)"
      />
    </div>
  </div>
</template>

<script>
import Answer from "./Answer.vue";

export default {
  name: "question",
  props: {
    questionData: Object,
  },
  components: {
    Answer,
  },
};
</script>

<style>
</style>