<template>
  <div class="question__item" @click.capture="addRemovePoint">
    <img v-if="answerData.imgUrl" :src="this.image" alt="img" />
    <img v-if="answerData.imgUrl2" :src="this.image2" alt="img" />
    <label class="question__label">
      {{ answerData.answer }}
      <input name="answer" type="radio" class="question__option" />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "answer",
  props: {
    answerData: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      image:
        this.answerData.imgUrl != null
          ? require(`@/${this.answerData.imgUrl}`)
          : null,
      image2:
        this.answerData.imgUrl2 != null
          ? require(`@/${this.answerData.imgUrl2}`)
          : null,
    };
  },
  methods: {
    addRemovePoint(e) {
      if (
        e.target.tagName === "IMG" &&
        e.target.nextElementSibling.tagName === "IMG"
      ) {
        e.target.nextElementSibling.nextElementSibling.children[0].checked = true;
      } else if (e.target.tagName === "IMG") {
        e.target.nextElementSibling.children[0].checked = true;
      } else if (e.target.tagName === "LABEL") {
        e.target.children[0].checked = true;
      } else if (e.target.className === "question__item") {
        e.target.lastElementChild.children[0].checked = true;
      }
      {
        e.target.checked = true;
      }

      this.$emit("add-point");
    },
  },
};
</script>

<style>
</style>