<template>
  <transition name="question">
    <main class="question main-wrapper__question-section">
      <div class="question__order">
        <p>{{ this.questionIndex + 1 }}</p>
        /
        <p>{{ questionsLength }}</p>
      </div>
      <Question
        :questionData="currentQuestion"
        :key="questionIndex"
        @add-point="addPoint"
      />
      <NextBtn
        :mode="this.isFinished"
        @next-question="nextQuestion"
        :disabled="this.isUserMakeAnswer"
      />
    </main>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import Question from "../components/Question.vue";
import NextBtn from "../components/NextBtn.vue";
import { mapActions } from "vuex";

export default {
  name: "QuestionPage",
  components: {
    Question,
    NextBtn,
  },
  data() {
    return {
      arr: [],
      questionIndex: 0,
      isFinished: false,
      userPoints: 0,
      userResponsArr: [],
      goal: 0,
      isUserMakeAnswer: false,
    };
  },
  mounted() {
    const randomArr = this.shuffle(
      Array.from(Array(this.questionsLength).keys())
    ); // create shuffle arr for random order
    this.arr = randomArr;
    this.userResponsArr = Array.from(Array(this.questionsLength)).fill(false);
  },
  computed: {
    ...mapGetters(["questionsLength"]),
    currentQuestion() {
      // const currentQuestionIndex = this.arr[this.questionIndex];

      return this.$store.getters.questionById(this.questionIndex);
    },
  },
  methods: {
    ...mapActions(["updateUserPoints"]),
    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
    nextQuestion() {
      this.isUserMakeAnswer = false;

      if (this.questionIndex === this.arr.length - 2) {
        this.questionIndex += 1;
        this.isFinished = true;
      } else {
        this.questionIndex += 1;
      }
    },
    addPoint(bool) {
      this.isUserMakeAnswer = true;
      this.userResponsArr[this.questionIndex] = bool;
      this.updateUserPoints(this.userResponsArr.filter((el) => el).length);
    },
  },
};
</script>

<style>
</style>