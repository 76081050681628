
<template>
  <div class="btn-wrapper">
    <button
      v-if="!mode"
      @click="$emit('next-question')"
      class="btn rules__btn"
      :class="{ disable: !this.disabled }"
    >
      Далі
    </button>
    <router-link v-else class="btn rules__btn" to="/result">
      Дізнатись результат
    </router-link>
  </div>
</template>

<script>
export default {
  name: "next-btn",
  props: {
    mode: {
      type: Boolean,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: true,
    },
  },
};
</script>

<style>
</style>